exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-cara-src-templates-cara-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-cara/src/templates/cara.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-cara-src-templates-cara-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-website-migration-backup-js": () => import("./../../../src/pages/services/website-migration-backup.js" /* webpackChunkName: "component---src-pages-services-website-migration-backup-js" */),
  "component---src-pages-services-website-seo-js": () => import("./../../../src/pages/services/website-seo.js" /* webpackChunkName: "component---src-pages-services-website-seo-js" */),
  "component---src-pages-services-wordpress-development-js": () => import("./../../../src/pages/services/wordpress-development.js" /* webpackChunkName: "component---src-pages-services-wordpress-development-js" */),
  "component---src-pages-services-wordpress-maintenance-js": () => import("./../../../src/pages/services/wordpress-maintenance.js" /* webpackChunkName: "component---src-pages-services-wordpress-maintenance-js" */)
}

